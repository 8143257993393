<template>
  <div>
    <v-dialog
      v-model="dialog"
      content-class="popup deliveryPicker"
      :max-width="confirm ? '730px' : '688px'"
      :scrollable="confirm"
      persistent
    >
      <v-card>
        <v-card-title>
          <div @click="dialog = false">
            <svg class="icon icon--22 cursor--pointer">
              <use href="../assets/svg/symbol-defs.svg#icon-times"/>
            </svg>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="deliveryPicker__content faq">
            <template v-if="!confirm">
              <div class="subtitle semibold color--blue">Jakým způsobem nám zařízení předáte?</div>
              <v-expansion-panels
                :accordion="true"
                :flat="true"
                :hover="true"
                :tile="true"
              >
                <v-expansion-panel
                  :ripple="false"
                >
                  <v-expansion-panel-header
                    class="semibold"
                    :hide-actions="true"
                  >
                    <template v-slot:default="{ open }">
                      Přinesu osobně na podací místo DPD <span class="color--green">zdarma</span>
                      <div :class="[open ? 'is-opened' : 'is-closed']">
                        <svg class="icon fill--blue">
                          <use href="../assets/svg/symbol-defs.svg#icon-arrow"/>
                        </svg>
                      </div>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="faq__message">
                      Na e-mail vám zašleme přepravní štítek. Ten vytisknete a nalepíte na zásilku. Zásilku odevzdáte na
                      jakémkoliv DPD podacím místě.
                      <a href="/dpd" target="_blank">
                        Seznam podacích míst
                        <svg class="icon icon--link fill--blue">
                          <use href="../assets/svg/symbol-defs.svg#icon-link"/>
                        </svg>
                      </a>
                      <v-btn
                        class="mbtn mbtn--green"
                        plain
                        small
                        :depressed="true"
                        :ripple="false"
                        @click="sendDelivery('parcelshop')"
                      >
                        {{ $t('alza.confirm') }}
                      </v-btn>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel
                  :ripple="false"
                >
                  <v-expansion-panel-header
                    class="semibold"
                    :hide-actions="true"
                  >
                    <template v-slot:default="{ open }">
                      Zařízení vyzvedne kurýr na adrese <span class="color--green">zdarma</span>
                      <div :class="[open ? 'is-opened' : 'is-closed']">
                        <svg class="icon fill--blue">
                          <use href="../assets/svg/symbol-defs.svg#icon-arrow"/>
                        </svg>
                      </div>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="faq__message">
                      Kurýr balík zdarma převezme na vámi určeném místě v domluvený čas.
                      <v-form class="app__form">
                        <div class="formItem">
                          <v-text-field
                            name="customer_data_street"
                            class="formInput--street"
                            v-model="address.street"
                            :placeholder="$t('alza.contract_form_data.placeholders.street')"
                            :error-messages="pickupAddressErrors.street"
                            :rules="[rules.required, rules.max(35)]"
                            solo
                            flat
                            hide-details="auto"
                          />
                          <div class="app__label">
                            {{ $t('alza.contract_form_data.street_with_number') }}&nbsp; <span class="color--redTone2">*</span>
                          </div>
                        </div>
                        <div class="formItem formItem--gcUnset">
                          <v-text-field
                            name="customer_data_city"
                            v-model="address.city"
                            :placeholder="$t('alza.contract_form_data.placeholders.city')"
                            :error-messages="pickupAddressErrors.city"
                            solo
                            flat
                            hide-details="auto"
                          />
                          <div class="app__label">
                            {{ $t('buyout.contractForm.city') }}&nbsp; <span class="color--redTone2">*</span>
                          </div>
                        </div>
                        <div class="formItem formItem--gcUnset postcode">
                          <v-text-field
                            name="customer_data_postcode"
                            v-model="address.post_code"
                            :placeholder="$t('alza.contract_form_data.placeholders.postcode')"
                            :error-messages="pickupAddressErrors.postcode"
                            solo
                            flat
                            hide-details="auto"
                          />
                          <div class="app__label">
                            {{ $t('buyout.contractForm.postcode') }}&nbsp; <span class="color--redTone2">*</span>
                          </div>
                        </div>
                        <div class="formItem formItem--gcUnset">
                          <v-text-field
                            name="customer_data_country"
                            v-model="address.country"
                            placeholder="Česká republika"
                            disabled
                            solo
                            flat
                            hide-details="auto"
                          />
                          <div class="app__label">
                            {{ $t('alza.contract_form_data.country') }}&nbsp; <span class="color--redTone2">*</span>
                          </div>
                        </div>
                        <div class="formItem--dates">
                          <v-slide-group
                            show-arrows
                            mandatory
                            v-model="selectedDeliveryDate"
                          >
                            <v-slide-item
                              v-for="(date, index) in dates"
                              :key="index"
                              v-slot="{ active, toggle }"
                            >
                              <v-btn
                                :class="['mbtn', active ? 'mbtn--blue' : '']"
                                plain
                                small
                                rounded
                                :depressed="true"
                                :ripple="false"
                                @click="toggle"
                              >
                                <span class="date date--text">{{ date.text }}&nbsp;</span>
                                <span class="date date--date">{{ date.date }}</span>
                              </v-btn>
                            </v-slide-item>
                          </v-slide-group>
                        </div>
                      </v-form>
                      <v-btn
                        class="mbtn mbtn--green"
                        plain
                        small
                        :depressed="true"
                        :ripple="false"
                        :disabled="isButtonDisabled"
                        @click="sendDelivery('courier')"
                      >
                        {{ $t('alza.confirm') }}
                      </v-btn>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
            <div class="deliveryPicker__personally text-center" v-if="confirmPersonally">
              <svg class="icon icon--check fill--green">
                <use href="../assets/svg/symbol-defs.svg#icon-success"/>
              </svg>
              <div class="subtitle color--green semibold">Hotovo</div>
              <div class="deliveryPicker__grid">
                <div class="gridItem">
                  <div class="step color--white semibold">1</div>
                  <svg class="icon fill--blue">
                    <use href="../assets/svg/symbol-defs.svg#icon-email"/>
                  </svg>
                  <div class="desc">
                    Na váš e-mail byl odeslán přepravní štítek.
                  </div>
                </div>
                <div class="gridItem">
                  <div class="step color--white semibold">2</div>
                  <svg class="icon fill--blue">
                    <use href="../assets/svg/symbol-defs.svg#icon-print"/>
                  </svg>
                  <div class="desc">
                    Štítek vytiskněte a nalepte <span class="noWrap">na zásilku.</span>
                  </div>
                </div>
                <div class="gridItem">
                  <div class="step color--white semibold">3</div>
                  <svg class="icon fill--blue">
                    <use href="../assets/svg/symbol-defs.svg#icon-box"/>
                  </svg>
                  <div class="desc">
                    Zásilku odevzdejte na jakémkoliv
                    <a href="/dpd" target="_blank">
                      DPD podacím místě
                      <svg class="icon icon--link fill--blue">
                        <use href="../assets/svg/symbol-defs.svg#icon-link"/>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="deliveryPicker__courier text-center" v-if="confirmCourier">
              <svg class="icon icon--check fill--green">
                <use href="../assets/svg/symbol-defs.svg#icon-success"/>
              </svg>
              <div class="subtitle color--green semibold">Přeprava zařízení objednána</div>
              <div class="deliveryPicker__grid">
                <div class="gridItem">
                  <div class="step color--white semibold">1</div>
                  <svg class="icon fill--blue">
                    <use href="../assets/svg/symbol-defs.svg#icon-email"/>
                  </svg>
                  <div class="desc">
                    Na váš e-mail byl odeslán přepravní štítek.
                  </div>
                </div>
                <div class="gridItem">
                  <div class="step color--white semibold">2</div>
                  <svg class="icon fill--blue">
                    <use href="../assets/svg/symbol-defs.svg#icon-print"/>
                  </svg>
                  <div class="desc">
                    Štítek vytiskněte a nalepte <span class="noWrap">na zásilku.</span>
                  </div>
                </div>
                <div class="gridItem">
                  <div class="step color--white semibold">3</div>
                  <svg class="icon fill--blue">
                    <use href="../assets/svg/symbol-defs.svg#icon-transport"/>
                  </svg>
                  <div class="desc">
                    Počkejte na SMS od kurýra s přesným termínem svozu.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions v-if="confirm">
          <v-btn
            class="mbtn mbtn--gray"
            plain
            small
            :depressed="true"
            :ripple="false"
            @click="dialog = false"
          >
            {{ $t('alza.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
		<v-dialog
			v-model="addressCorrectionDialog"
			content-class="popup popup--address"
			persistent
			max-width="600px"
		>
			<v-card>
				<v-card-title>
					<div @click="addressCorrectionDialog = false">
						<svg class="icon icon--22 cursor--pointer">
							<use href="../assets/svg/symbol-defs.svg#icon-times" />
						</svg>
					</div>
				</v-card-title>
				<v-card-text>
					<div class="app__subtitle mt-5">Potvrzení adresy</div>
					<v-progress-circular
						v-if="addressCorrectionData === null"
						indeterminate
						color="primary"
						size="60"
						class="d-flex mt-16 mb-12 ma-auto"
					/>
					<template v-else>
						<div class="mt-10 mb-8">Prosím, zkontrolujte a potvrďte vaši doručovací adresu:</div>
						<v-radio-group v-model="selectedAddress">
							<v-radio
								value="original"
								:ripple="false"
							>
								<template #label>
									<div class="wrapper">
										<div class="bold">Co jste zadali</div>
										<div class="desc">{{ displayOriginalAddress }}</div>
									</div>
								</template>
							</v-radio>
							<v-radio
								value="corrected"
								:ripple="false"
							>
								<template #label>
									<div class="wrapper">
										<div class="bold color--blueTone3">Doporučeno</div>
										<div class="desc">{{ displayCorrectedAddress }}</div>
									</div>
								</template>
							</v-radio>
						</v-radio-group>
					</template>
				</v-card-text>
				<v-card-actions>
					<v-btn
						class="mbtn mbtn--gray"
						plain
						small
						:depressed="true"
						:ripple="false"
						@click="rejectAddress"
					>
						Zrušit
					</v-btn>
					<v-btn
						class="mbtn mbtn--green"
						plain
						small
						:depressed="true"
						:ripple="false"
						@click="confirmAddress"
					>
						Potvrdit
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="incompleteAddressDialog"
			content-class="popup popup--address"
			persistent
			max-width="600px"
		>
			<v-card>
				<v-card-title>
					<div @click="incompleteAddressDialog = false">
						<svg class="icon icon--22 cursor--pointer">
							<use href="../assets/svg/symbol-defs.svg#icon-times" />
						</svg>
					</div>
				</v-card-title>
				<v-card-text>
					<div class="app__subtitle mt-5">
						Chybějící informace <span class="noWrap">o adrese</span>
					</div>
					<v-progress-circular
						v-if="incompleteAddressData === null"
						indeterminate
						color="primary"
						size="60"
						class="d-flex mt-16 mb-12 ma-auto"
					/>
					<template v-else>
						<div class="mt-10 mb-4">Byla zjištěna následující chybějící informace:</div>
						<ul class="mb-20">
							<li v-for="missing in incompleteAddressData.missing_components" :key="missing">
								{{ missingComponentMessages[missing] || missing }}:
								<strong>{{ getUserEnteredData(missing) }}</strong>
							</li>
						</ul>
						<div class="app__form app__form--gap-10 mt-4">
							<div
								v-for="missing in incompleteAddressData.missing_components"
								:key="missing"
								class="formItem"
							>
								<v-text-field
									:value="getUserEnteredData(missing)"
									filled
									flat
									hide-details="auto"
									@input="updateCustomerData(missing, $event)"
								/>
								<div class="app__label">
									{{ missingComponentMessages[missing] || missing }}&nbsp; <span class="color--redTone2">*</span>
								</div>
							</div>
						</div>
					</template>
				</v-card-text>
				<v-card-actions>
					<v-btn
						class="mbtn mbtn--gray"
						plain
						small
						:depressed="true"
						:ripple="false"
						@click="closeIncompleteAddressDialog"
					>
						Zrušit
					</v-btn>
					<v-btn
						class="mbtn mbtn--green"
						plain
						small
						:depressed="true"
						:ripple="false"
						@click="confirmIncompleteAddress"
					>
						Potvrdit
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="suspiciousAddressDialog"
			content-class="popup popup--address"
			persistent
			max-width="600px"
		>
			<v-card>
				<v-card-title>
					<div @click="suspiciousAddressDialog = false">
						<svg class="icon icon--22 cursor--pointer">
							<use href="../assets/svg/symbol-defs.svg#icon-times" />
						</svg>
					</div>
				</v-card-title>
				<v-card-text>
					<div class="app__subtitle mt-5">Podezřelá adresa</div>
					<v-progress-circular
						v-if="!suspiciousAddressData"
						indeterminate
						color="primary"
						size="60"
						class="d-flex mt-16 mb-12 ma-auto"
					/>
					<template v-else>
						<div class="mt-10 mb-4">Tato adresa byla identifikována jako podezřelá:</div>
						<div class="suspiciousBox">{{ suspiciousAddressData.fullAddress }}</div>
						<div>Podezřelé části: <strong>{{ suspiciousAddressData.suspiciousParts }}</strong></div>
						<div class="desc mt-10 text-center">
							<svg class="icon icon--22 fill--blue">
								<use href="../assets/svg/symbol-defs.svg#icon-info" />
							</svg>
							<div>
								Prosím zkontrolujte a upravte adresu, nebo ji potvrďte, <span class="noWrap">pokud je správná.</span>
							</div>
						</div>
					</template>
				</v-card-text>
				<v-card-actions>
					<v-btn
						class="mbtn mbtn--green"
						plain
						small
						:depressed="true"
						:ripple="false"
						@click="confirmSuspiciousAddress"
					>
						Potvrdit
					</v-btn>
					<v-btn
						class="mbtn mbtn--gray"
						plain
						small
						:depressed="true"
						:ripple="false"
						@click="closeSuspiciousAddressDialog"
					>
						Změnit
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
  </div>
</template>

<script>
import * as moment from "moment";
import "moment/dist/locale/cs";
import {apiAxios} from "js-mp/src/js/buyout";
import {URLS as API_URLS} from "../store/alzaBuyout";
import {eventBus} from "@/main";
import {validationRules} from '@/mixins/validation';
import {GETTER_TYPES} from "js-mp/src/vue/store/buyout/_getter-types";
import {ACTION_TYPES} from "js-mp/src/vue/store/buyout/_action-types";
import {mapActions, mapGetters, mapState} from "vuex";
import {VUEX_BUYOUT} from "js-mp/src/vue/store/buyout";
import {CzechHolidays} from "czech-holidays";

export default {
  name: "DeliveryPicker",
  mixins: [validationRules],
  data() {
    return {
      dialog: true,
      confirm: false,
      confirmPersonally: false,
      confirmCourier: false,
      selectedDeliveryDate: null,
      pickupAddressErrors: {
        city: '',
        street: '',
        post_code: '',
        country_identifier: '',
      },
      address: {
        city: '',
        street: '',
        post_code: '',
        country_identifier: '',
        country: 'Česká republika',
        was_address_confirmed: false,
      },
      dates: [],
      addressCorrectionDialog: false,
      addressCorrectionData: null,
      incompleteAddressDialog: false,
      incompleteAddressData: null,
      selectedAddress: "",
      missingComponentMessages: {
        "street_number": "Ulice a číslo popisné",
        "city": "Město",
        "postal_code": "PSČ",
      },
      suspiciousAddressDialog: false,
      suspiciousAddressData: {address: ""},
    }
  },
  created() {
    if (!this[GETTER_TYPES.STATES].length) this[ACTION_TYPES.LOAD_STATES]();
    this.getDates();
  },
  computed:{
    ...mapState(VUEX_BUYOUT.NAMESPACE, [
      'userBuyoutCode',
    ]),
    ...mapGetters(VUEX_BUYOUT.NAMESPACE, [
      GETTER_TYPES.STATES,
    ]),
    isButtonDisabled(){
      return !(this.address.city && this.address.street && (this.address.street.length < 36) && this.address.post_code && typeof this.selectedDeliveryDate === 'number')
    },
    displayOriginalAddress() {
      return `Co jste zadali: ${this.originalAddress.street}, ${this.originalAddress.city}, ${this.originalAddress.postcode}`;
    },

    displayCorrectedAddress() {
      return `Doporučeno: ${this.correctedAddress.street}, ${this.correctedAddress.city}, ${this.correctedAddress.postcode}`;
    }
  },
  methods: {
    ...mapActions(VUEX_BUYOUT.NAMESPACE, [
      ACTION_TYPES.LOAD_STATES,
    ]),
    getDates(){
      moment.locale("cs");
      const noOfSlots = 3;
      const now = moment()
      const slots=[];
      now.add(1, "days");
      const holidays = CzechHolidays(now.year());
      const isDeadlineOffset = !!(now.hours() >= 20 && now.minutes() > 0);
      const isWeekend = (day) => {
        return (day === 6) || (day === 0);
      }
      const isPublicHoliday = (date) => {
        return holidays.some(e => e.m === (date.month() + 1) && e.d === date.date());
      }
      const isValidDate = (date) => {
        return !(isWeekend(date.day()) || isPublicHoliday(date));
      }
      if (isDeadlineOffset) { now.add(1, "days"); }
      do {
        if (isValidDate(now)) {
          slots.push({"date": now.format("DD.MM."), "text": now.calendar(null, {
            sameDay: "[dnes]",
            nextDay: "[zítra]",
            nextWeek: "dddd",
            lastDay: "[včera]",
            lastWeek: "[minulý] dddd",
            sameElse: "DD.MM.YYYY"
          })});
        }
        now.add(1, "days");
      } while (!isValidDate(now) || slots.length < noOfSlots);
      this.dates = slots;
    },
    formatDate(date) {
      if (moment(date, 'DD.MM.YYYY').isValid()) {
        let selectedDate = moment(date, 'DD.MM.YYYY');
        if (selectedDate.isBefore(moment())) {   // selected date is in the next year - we must add a year
          selectedDate.add(1, 'year');
        }
        return selectedDate.format('YYYY-MM-DD');
      }
      return date;
    },
    async sendDelivery(type){
      try {
        this.pickupAddressErrors={
          city: '',
          street: '',
          post_code: '',
        }
        await apiAxios.post(
          `${API_URLS.BUYOUT}/${
            API_URLS.PICKUP_ADDRESS
          }/${this.userBuyoutCode}`,
          {
            street: type === 'courier' ? this.address.street : null,
            city: type === 'courier' ? this.address.city : null,
            postcode: type === 'courier' ? this.address.post_code : null,
            country_identifier: 'CZE',
            delivery_date: type === 'courier' ? this.formatDate(this.dates[this.selectedDeliveryDate].date) : null,
            delivery_type: type,
            was_address_confirmed: type === 'parcelshop' ? true : this.address.was_address_confirmed,
          }
        );
        this.confirm = true;
        if(type==='courier') this.confirmCourier = true;
        else this.confirmPersonally = true
      } catch (err) {
        if (err.response && err.response.status === 400) {
          this.pickupAddressErrors=err.response.data
        } else if (err.response && err.response.status === 409) {
          eventBus.$emit("showErrorMessage", {message: err.response.data.error.join(", "), is_error: true});
        } else {
          eventBus.$emit('showErrorMessage', {message: 'Chyba při zpracovávání požadavku', is_error: true});
        }
      }
      if (this.pickupAddressErrors.street_and_number && this.pickupAddressErrors.street_and_number.includes("Missing house number")) {
        this.incompleteAddressData = {
          missing_components: ["street_number"],
        };
        this.incompleteAddressDialog = true;
      } else if (this.pickupAddressErrors.x_address) {
        const validationResult = this.pickupAddressErrors.x_address.validation_result;

        if (validationResult) {
          if (validationResult === "corrected") {
            this.addressCorrectionData = this.pickupAddressErrors.x_address;
            this.selectedAddress = "corrected";
            this.setOriginalAddress();
            this.setCorrectedAddress();
            this.addressCorrectionDialog = true;
          }
          if (validationResult === "incomplete") {
            this.incompleteAddressData = this.pickupAddressErrors.x_address;
            this.incompleteAddressDialog = true;
          }
          if (validationResult === "suspicious") {
            this.suspiciousAddressData = this.pickupAddressErrors.x_address;
            this.setSuspiciousAddress();
            this.suspiciousAddressDialog = true;
          }
        }
      }
    },
    confirmAddress() {
      if (this.selectedAddress === "original") {
        this.address.street = this.originalAddress.street;
        this.address.city = this.originalAddress.city;
        this.address.post_code = this.originalAddress.postcode;
      } else if (this.selectedAddress === "corrected") {
        this.address.street = this.correctedAddress.street;
        this.address.city = this.correctedAddress.city;
        this.address.post_code = this.correctedAddress.postcode;
      }

      this.address.was_address_confirmed = true;
      this.addressCorrectionDialog = false;
    },

    rejectAddress() {
      this.addressCorrectionDialog = false;
    },
    setOriginalAddress() {
      this.originalAddress = {
        street: this.address.street || "",
        city: this.address.city || "",
        postcode: this.address.post_code || "",
      };
    },

    setCorrectedAddress() {
      if (this.addressCorrectionData && this.addressCorrectionData.components) {
        const {components} = this.addressCorrectionData;

        const street = components.route ? components.route.value : "";
        const premise = components.premise ? components.premise.value : "";
        const city = components.locality ? components.locality.value : "";
        const postcode = components.postal_code ? components.postal_code.value : "";

        this.correctedAddress = {
          street: street + (premise ? ` ${premise}` : ""),
          city,
          postcode,
        };
      }
    },
    confirmIncompleteAddress() {
      if (this.incompleteAddressData && this.incompleteAddressData.missing_components) {
        this.incompleteAddressData.missing_components.forEach(missing => {
          const userData = this.getUserEnteredData(missing);

          if (missing === "street_number") {

            if (this.address.street && !this.address.street.includes(userData)) {
              this.address.street += ` ${userData}`;
            }
          } else {
            this.address[missing] = userData;
          }
        });
      }
      // this.address.was_address_confirmed = true;
      this.incompleteAddressDialog = false;
      this.sendDelivery('courier');
    },

    closeIncompleteAddressDialog() {
      this.incompleteAddressDialog = false;
    },
    getUserEnteredData(component) {
      const addressComponentMap = {
        "street_number": this.address.street,
        "city": this.address.city,
        "postal_code": this.address.post_code,
      };
      return addressComponentMap[component];
    },
    updateCustomerData(missingComponent, newValue) {
      const addressComponentMap = {
        "street_number": "street",
        "city": "city",
        "postal_code": "post_code",
      };

      const pickUpAddressKey = addressComponentMap[missingComponent];

      if (pickUpAddressKey) {
        this.address[pickUpAddressKey] = newValue;
      }
    },
    closeSuspiciousAddressDialog() {
      this.suspiciousAddressDialog = false;
    },
    confirmSuspiciousAddress() {
      if (!this.suspiciousAddressData || !this.suspiciousAddressData.fullAddress) return;

      const parts = this.suspiciousAddressData.fullAddress.split(", ");

      if (parts.length < 3) return;

      const streetAndNumber = parts.slice(0, 2).join(" ");
      const city = parts[2];
      const postCode = parts.length > 3 ? parts[3] : "";

      this.address.street = streetAndNumber;
      this.address.city = city;
      this.address.post_code = postCode;

      this.address.was_address_confirmed = true;
      this.suspiciousAddressDialog = false;

      this.sendDelivery('courier');
    },
    setSuspiciousAddress() {
      if (!this.suspiciousAddressData || !this.suspiciousAddressData.components) return;

      const {route, premise, locality, postal_code} = this.suspiciousAddressData.components;

      this.suspiciousAddressData.fullAddress = [
        route?.value,
        premise?.value,
        locality?.value,
        postal_code?.value,
      ].filter(part => part !== undefined).join(", ");

      this.suspiciousAddressData.suspiciousParts = Object.keys(this.suspiciousAddressData.components)
          .filter(key => this.suspiciousAddressData.components[key]?.suspicious === "True")
          .map(key => this.suspiciousAddressData.components[key]?.value)
          .join(", ");
    }
  }
}
</script>

<template>
  <div class="buyout">
    <div class="app__subtitle color--blue">{{ $t('alza.buyout') }}</div>
    <div class="text-center">
      {{ $t('alza.buyout_subtitle') }}
    </div>
    <v-stepper :value="currentStep" flat>
      <div :class="['fixedSpacer', !isHeaderFixed ? 'is-hidden' : '']"/>
      <div :class="['v-stepper__headerWrapper', isHeaderFixed ? 'is-fixed' : '']">
        <v-stepper-header>
          <v-stepper-step
            :complete="chosenDevice"
            step="1"
            :editable="isStep1Editable"
            @click="isStep1Editable ? goBackDeviceState(true) : ''"
          >
            {{ $t('alza.find_device') }}
          </v-stepper-step>
          <v-divider :class="appraisedDevice ? 'is-complete' : ''"/>
          <v-stepper-step
            :complete="appraisedDevice"
            step="2"
            :editable="isStep2Editable"
            @click="isStep2Editable ? goBackDeviceState() : ''"
          >
            {{ $t('alza.device_appraisal') }}
          </v-stepper-step>
          <v-divider :class="isBuyoutSent ? 'is-complete' : ''"/>
          <v-stepper-step
            :complete="isBuyoutSent"
            step="3"
          >
            {{ $t('alza.contract_form') }}
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step
            :complete="buyoutDone"
            step="4"
          >
            {{ $t('alza.done') }}
          </v-stepper-step>
        </v-stepper-header>
      </div>
      <v-stepper-items>
        <v-stepper-content step="1">
          <new-device-finder/>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="buyout__steps">
            <div class="buyout__step--left">
              <div class="stepTitle bold color--darkBlue">
                {{ $t('alza.device_state') }}
              </div>
              <new-device-state v-if="!chosenDeviceState && selectedColor"/>
              <new-appraisal-form
                id="deviceForms"
                v-show="chosenDeviceState"
              >
                <template #title>
                  <div class="app__subtitle">
                    <div class="desc">
                      {{ $t('console.detail_pricing_according_to_conditions') }}
                    </div>
                  </div>
                </template>
              </new-appraisal-form>
              <div class="btnsWrapper">
                <v-btn
                  class="mbtn mbtn--gray"
                  plain
                  small
                  :depressed="true"
                  :ripple="false"
                  @click="goBackDeviceState()"
                >
                  <svg class="icon icon--left icon--flipped fill--black">
                    <use href="../../assets/svg/symbol-defs.svg#icon-arrow"/>
                  </svg>
                  {{ $t('alza.go_back') }}
                </v-btn>
                <v-btn
                  class="mbtn mbtn--green"
                  plain
                  small
                  :depressed="true"
                  :ripple="false"
                  :disabled="!qualitySelected || (qualitySelected && chosenDeviceState && canNotBeBuyout)"
                  @click="deviceStateContinue"
                >
                  {{ $t('alza.continue') }}
                  <svg class="icon icon--right fill--white">
                    <use href="../../assets/svg/symbol-defs.svg#icon-arrow"/>
                  </svg>
                </v-btn>
              </div>
            </div>
            <div class="buyout__step--right buyout__summary--deviceState">
              <new-device-summary v-if="selectedColor && !appraisedDevice" />
            </div>
          </div>
        </v-stepper-content>
        <v-stepper-content step="3">
          <div class="buyout__steps">
            <div class="buyout__step--left">
              <div class="stepTitle bold color--darkBlue">
                {{ $t('alza.contract_form_data.contract_info') }}
              </div>
              <contract-form v-if="selectedCategory"/>
              <div class="btnsWrapper">
                <v-btn
                  class="mbtn mbtn--gray"
                  plain
                  small
                  :depressed="true"
                  :ripple="false"
                  @click="goBackDeviceState()"
                >
                  <svg class="icon icon--left icon--flipped fill--black">
                    <use href="../../assets/svg/symbol-defs.svg#icon-arrow"/>
                  </svg>
                  {{ $t('alza.go_back') }}
                </v-btn>
                <v-btn
                  class="mbtn mbtn--green"
                  plain
                  small
                  :depressed="true"
                  :ripple="false"
                  @click="sendBuyout"
                  :disabled="!contactFormIsValid || disableBuyoutSendBtn"
                >
                  {{ $t('alza.finish_buyout') }}
                  <svg class="icon icon--right fill--white">
                    <use href="../../assets/svg/symbol-defs.svg#icon-arrow"/>
                  </svg>
                </v-btn>
              </div>
            </div>
            <div class="buyout__step--right">
              <new-device-summary v-if="selectedColor" />
            </div>
          </div>
        </v-stepper-content>
        <v-stepper-content step="4">
          <div class="buyout__complete text-center">
            <svg class="icon">
              <use href="../../assets/svg/symbol-defs.svg#icon-success"/>
            </svg>
            <div class="subtitle semibold">
              {{ $t(`alza.buyout_finished`) }}
            </div>
            <div class="desc">
              {{ $t(`alza.buyout_email_sent`) }}
              {{ $t(`alza.buyout_thank_you`) }}
            </div>
            <div class="btnsWrapper">
              <v-btn
                class="mbtn mbtn--gray"
                plain
                :depressed="true"
                :ripple="false"
                @click="newBuyout"
              >
                {{ $t(`alza.buyout_another_device`) }}
              </v-btn>
              <v-btn
                class="mbtn mbtn--green"
                plain
                :depressed="true"
                :ripple="false"
                :href="getReturnURL"
              >
                {{ $t('alza.go_back_to_alza') }}
              </v-btn>
            </div>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import {VUEX_ALZA} from '__store__/alzaBuyout';
import ContractForm from '__components__/ContractForm/App';
import NewDeviceFinder from '__components__/NewDeviceFinder/App';
import NewDeviceSummary from '__components__/NewDeviceSummary/App';
import NewDeviceState from '__components__/NewDeviceState/App';
import NewAppraisalForm from '__components__/NewAppraisalForm/App';
import {VUEX_BUYOUT} from 'js-mp/src/vue/store/buyout';
import {mapMutations, mapState, mapActions, mapGetters} from 'vuex';
import {MUTATION_TYPES} from 'js-mp/src/vue/store/buyout/_mutation-types';
import {ACTION_TYPES} from 'js-mp/src/vue/store/buyout/_action-types';
import {GETTER_TYPES} from 'js-mp/src/vue/store/buyout/_getter-types';
import {GETTER_TYPES as ALZA_BUYOUT_GT} from "__store__/alzaBuyout/_getter-types";
import {MUTATION_TYPES as ALZA_BUYOUT_MT} from '__store__/alzaBuyout/_mutation-types';
import SanitizeMixin from 'js-mp/src/vue/mixins/buyout/sanitize';
import isEmpty from "lodash/isEmpty";
import {eventBus} from "@/main";

export default {
  name: "DeviceBuyout",
  mixins: [SanitizeMixin],
  components: {
    ContractForm,
    NewDeviceSummary,
    NewDeviceFinder,
    NewDeviceState,
    NewAppraisalForm
  },
  data() {
    return {
      isActive: false,
      isDatepickerMenuVisible: false,
      isOrderDatepickerMenuVisible: false,
      buyoutDone: false,
      isHeaderFixed: false,
      isBuyoutSent: false,
      baseStep: 1,
      was_address_confirmed: true
    }
  },
  computed: {
    ...mapState(VUEX_BUYOUT.NAMESPACE, [
      'appraisal',
      'isAppraisalEditable',
      'userBuyoutCode',
      'canNotBeBuyout',
      'appraisalErrors',
      'selectedColor',
      'buyoutAdditionalInfo',
      'selectedCategory',
      'disableBuyoutSendBtn'
    ]),
    ...mapState(VUEX_ALZA.NAMESPACE, [
      'contactFormIsValid',
    ]),
    ...mapGetters(VUEX_ALZA.NAMESPACE, [
      ALZA_BUYOUT_GT.GET_BUYOUT_PAYLOAD,
      ALZA_BUYOUT_GT.GET_DEVICE_CHOSEN,
      ALZA_BUYOUT_GT.GET_DEVICE_STATE_CHOSEN,
      ALZA_BUYOUT_GT.GET_DEVICE_APPRAISED,
      ALZA_BUYOUT_GT.GET_CONTACT_FORM_IS_VALID
    ]),
    ...mapGetters(VUEX_BUYOUT.NAMESPACE, [
      GETTER_TYPES.SELECTED_QUALITY,
      GETTER_TYPES.COMMON_ERROR,
      GETTER_TYPES.APPRAISAL,
      GETTER_TYPES.APPRAISAL_RESULTS,
      GETTER_TYPES.SELECTED_BONUS,
      GETTER_TYPES.CONTRACT_FORM
    ]),
    chosenDevice: {
      get() {
        return this[ALZA_BUYOUT_GT.GET_DEVICE_CHOSEN];
      },
      set(v) {
        this[ALZA_BUYOUT_MT.SET_DEVICE_CHOSEN](v);
      }
    },
    appraisedDevice: {
      get() {
        return this[ALZA_BUYOUT_GT.GET_DEVICE_APPRAISED];
      },
      set(v) {
        this[ALZA_BUYOUT_MT.SET_DEVICE_APPRAISED](v);
      }
    },
    qualitySelected: {
      get() {
        return this[GETTER_TYPES.SELECTED_QUALITY];
      },
      set(v) {
        this[MUTATION_TYPES.SET_SELECTED_QUALITY](v);
      }
    },
    isCommonError: {
      get() {
        return this[GETTER_TYPES.COMMON_ERROR];
      },
      set(v) {
        this[MUTATION_TYPES.SET_COMMON_ERROR](v);
      }
    },
    chosenDeviceState: {
      get() {
        return this[ALZA_BUYOUT_GT.GET_DEVICE_STATE_CHOSEN];
      },
      set(v) {
        this[ALZA_BUYOUT_MT.SET_DEVICE_STATE_CHOSEN](v);
      },
    },
    form: {
			get() {
				return this[GETTER_TYPES.CONTRACT_FORM];
			}
		},
    currentStep() {
      let steps = 0
      if (this.chosenDevice) {
        steps++
      }
      if (this.appraisedDevice) {
        steps++
      }
      if (this.isBuyoutSent) {
        steps++
      }
      return this.baseStep + steps
    },
    getReturnURL() {
      try{
       return sessionStorage.getItem("returnURL");
      }
      catch(SecurityError){
        return this.default_return_page;
      }
    },
    isStep1Editable(){
      return (this.chosenDevice && !this.isBuyoutSent)
    },
    isStep2Editable(){
      return (this.chosenDevice && this.appraisedDevice && !this.isBuyoutSent)
    },
  },
  created() {
    this[MUTATION_TYPES.SET_IS_PRE_SALE_INFO](true);
    this[ACTION_TYPES.LOAD_PARTNERS]();
  },
  mounted() {
    window.addEventListener('scroll', this.fixedStepperHeader);
  },
  methods: {
    ...mapMutations(VUEX_BUYOUT.NAMESPACE, [
      MUTATION_TYPES.SET_STATE_TYPES,
      MUTATION_TYPES.SET_GENDERS,
      MUTATION_TYPES.SET_QUALITIES,
      MUTATION_TYPES.SET_SELECTED_CATEGORY,
      MUTATION_TYPES.SET_IS_PRE_SALE_INFO,
      MUTATION_TYPES.SET_SELECTED_BRAND,
      MUTATION_TYPES.SET_SELECTED_COLOR,
      MUTATION_TYPES.SET_SELECTED_MODEL,
      MUTATION_TYPES.SET_FORM_FIELD,
      MUTATION_TYPES.SET_BUYOUT_ADDITIONAL_INFO_ERRORS,
      MUTATION_TYPES.SET_USER_BUYOUT_CODE,
      MUTATION_TYPES.SET_SELECTED_QUALITY,
      MUTATION_TYPES.SET_IS_DIFFERENT_PICKUP_ADDRESS,
      MUTATION_TYPES.SET_PICKUP_ADDRESS,
      MUTATION_TYPES.SET_COMMON_ERROR
    ]),
    ...mapActions(VUEX_BUYOUT.NAMESPACE, [
      ACTION_TYPES.RESET_APPRAISAL,
      ACTION_TYPES.LOAD_PARTNERS,
    ]),
    ...mapMutations(VUEX_ALZA.NAMESPACE, [
      ALZA_BUYOUT_MT.SET_DEVICE_CHOSEN,
      ALZA_BUYOUT_MT.SET_DEVICE_STATE_CHOSEN,
      ALZA_BUYOUT_MT.SET_DEVICE_APPRAISED,
      ALZA_BUYOUT_MT.SET_DEVICE_STATE_CHOSEN,
      ALZA_BUYOUT_MT.SET_CONTACT_FORM_IS_VALID
    ]),
    setContractFormValue(key, value) {
			this[MUTATION_TYPES.SET_FORM_FIELD]({ key, value });
		},
    async newBuyout() {
      await this.resetBuyout();
      this.setFormValue('buyout_device.imei', null);
      this.setFormValue('buyout_device.note', '');
      this.setFormValue('buyout_device.new_device_imei', '');
      this.setFormValue('buyout_device.order_number', '');
      eventBus.$emit('resetValidation');
      await this.$vuetify.goTo('#buyout');
    },
    async resetBuyout() {
      this.chosenDevice = false;
      this.appraisedDevice = false;
      this.isBuyoutSent = false;
      this.chosenDeviceState = false;
      this[MUTATION_TYPES.SET_USER_BUYOUT_CODE](null);
      await this[ACTION_TYPES.RESET_APPRAISAL]();
      this[MUTATION_TYPES.SET_SELECTED_MODEL](null);
      this[MUTATION_TYPES.SET_IS_DIFFERENT_PICKUP_ADDRESS](false);
      this[MUTATION_TYPES.SET_SELECTED_COLOR](null);
      this[MUTATION_TYPES.SET_SELECTED_BRAND](null);
      this[MUTATION_TYPES.SET_SELECTED_CATEGORY](null);
      this[MUTATION_TYPES.SET_BUYOUT_ADDITIONAL_INFO_ERRORS]({});
      this[MUTATION_TYPES.SET_PICKUP_ADDRESS]({
          street: null,
          city: null,
          post_code: null
      });
    },
		setFormValue(key, value) {
			this[MUTATION_TYPES.SET_FORM_FIELD]({key, value});
		},
		async sendBuyout() {
      if (this.appraisalErrors['pickup_address']) {
        this.$delete(this.appraisalErrors, 'pickup_address');
      }
      if (!this[ALZA_BUYOUT_GT.GET_CONTACT_FORM_IS_VALID]) return;
      if (this.userBuyoutCode) this[MUTATION_TYPES.SET_USER_BUYOUT_CODE](null);
      let payload = this[ALZA_BUYOUT_GT.GET_BUYOUT_PAYLOAD];
      if(!this[GETTER_TYPES.SELECTED_BONUS] ){
        delete payload['buyout_device']['new_device_imei'];
      }
      let deviceForm = this[GETTER_TYPES.APPRAISAL_RESULTS].filter(item=>item).map(
        item => item.value
      );
      let deviceQualities = this[GETTER_TYPES.APPRAISAL].device_conditions
        .filter(cond => cond.type === 'quality')
        .map(cond => cond.choices[0]);

      deviceQualities.forEach(deviceQuality => {
        if (
          this[GETTER_TYPES.SELECTED_QUALITY].id === deviceQuality.appraisal_result && !deviceForm.includes(deviceQuality.value)
        )
          deviceForm.push(deviceQuality.value);
      });
      payload['device_condition_choices'] = deviceForm;
      payload['customer_data']['was_address_confirmed'] = this.was_address_confirmed;
      payload['pickup_address']['was_address_confirmed'] = this.was_address_confirmed;
			await this.$store.dispatch(
				`${VUEX_BUYOUT.NAMESPACE}/${ACTION_TYPES.SEND_APPRAISAL}`,
				{ payload: payload}
			);
      if (!isEmpty(this.appraisalErrors.device_condition_choices)) {
        this.appraisedDevice = false
				await this.$vuetify.goTo('#deviceForms');
			}
      if (this.isCommonError){
        eventBus.$emit('showErrorMessage', {message: 'Chyba při zpracovávání požadavku', is_error: true});
      }
      else if (isEmpty(this.appraisalErrors) && !this.isCommonError) {
        this.isBuyoutSent = true;
        await this.$vuetify.goTo('#buyout');
        this.setFormValue('buyout_device.new_device_imei','');
      }
      this.isCommonError = false
		},
    async deviceStateContinue() {
      if (this.qualitySelected && this.chosenDeviceState && !this.appraisedDevice) {
        this.appraisedDevice = true
      } else if (!this.chosenDeviceState) {
        if (this.selectedCategory.seo_name === 'foto-objektivy') {
          this.appraisedDevice = true
        }
        this.chosenDeviceState = true
      }
      await this.$vuetify.goTo('#buyout');
    },
    async goBackDeviceState(goToFirstStep=false){
      if (goToFirstStep){
        this.qualitySelected = null
        this.chosenDeviceState = false
        this.chosenDevice = false
        this.appraisedDevice = false
      }
      else{
        if(!this.appraisedDevice){
          this.qualitySelected = null
          this.chosenDeviceState = false
          this.chosenDevice = false
        } else if (this.selectedCategory.seo_name === 'foto-objektivy') {
          this.chosenDeviceState = false
          this.appraisedDevice = false
        } else {
          this.appraisedDevice = false
        }
      }
      await this.$vuetify.goTo('#buyout');
    },
    fixedStepperHeader() {
      const el = document.querySelector('.v-stepper__items');
      if(!el)
        return
      const elTop = el.getBoundingClientRect().top - 72;
      this.isHeaderFixed = elTop < 0;

      if (this.isHeaderFixed) {
        document.querySelector('.app__snack').classList.add('is-sticky');
      }
      else {
        document.querySelector('.app__snack').classList.remove('is-sticky');
      }
    }
  },
}
</script>
